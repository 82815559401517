/* eslint-disable react/no-unused-prop-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-lonely-if */

import { CURRENCY, FREE_TRIAL_DURATION } from 'constants/constants';
import styled from 'styled-components';
import PaymentScreenDemo from 'views/PaymentScreen/PaymentScreen.demo';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import {
  IFeature,
  IPlan
} from 'components/SubscriptionPlanContent/SubscriptionPlanContent';
import { Image, Flex } from 'styles';
import PaymentSuccess from 'assets/payment-sucess.png';
import PaymentFailure from 'assets/payment-fail.png';
import CircularProgress from '@mui/material/CircularProgress';
import { Box, Button, Card, Divider, Stack, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  BuyUserSubscription,
  GetSubscriptionDetails,
  UpgradeUserSubscription
} from 'services/api/subscriptionApi';
import { GetPaymentMethods, PaymentMethod } from 'services/api/paymentsApi';
import { IsMobile } from 'hooks/isMobile';
import { endpoints } from 'services/api/endpoints';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { fetchAllSubscription } from 'store/reducers/subscriptionReducer';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import CameraIcon from 'assets/camera_icon.png';
import Cry from 'assets/crying-baby.svg';
import Adfree from 'assets/ad-free.svg';
import Breath from 'assets/breathing-monitoring.svg';
import house from 'assets/smart_home.png';
import SleepAlert from 'assets/sleep-alert.svg';
import SleepTrack from 'assets/sleep-tracking.svg';
import Sharing from 'assets/people.svg';
import Support from 'assets/24-support.svg';
import CloudStorage from 'assets/cloud-storage.svg';
import VideoCameraIcon from 'assets/video-camera.svg';
import Spinner from 'components/Spinner';
import { ConfigContext } from 'context';
import InstaCard from 'components/InstaCard';
import { getCardImageProps } from 'utils/commonFunctions';

dayjs.extend(relativeTime);

const Info = styled.div`
  color: #000;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: -0.22px;
  margin-bottom: 10px;
`;

const PlanDetailPoint = styled.div`
  color: #000;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin-left: 10px;
`;

const Heading = styled.div`
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
`;

const Content = styled.div`
  color: #484c4f;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.16px;
`;

const ContactInfo = styled.div`
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.16px;
  text-decoration-line: underline;
`;

type IIconMap = {
  [key: string]: string;
};

const obj: IIconMap = {
  basic_features: CameraIcon,
  user_sharing: Sharing,
  '30_days_activity': CloudStorage,
  enhanced_support: Support,
  smart_home: house,
  cry_detection: Cry,
  sleep_tracking: SleepTrack,
  sleep_alerts: SleepAlert,
  breathing_monitoring: Breath,
  ad_free: Adfree,
  unlimited_camera_supported: VideoCameraIcon,
  '1_camera_supported': VideoCameraIcon
};

type IProps = {
  planSelected?: IPlan;
  purchaseId?: string;
  deviceId?: string;
  toggleModal?: () => void;
  isFreeTrial?: boolean;
  isUpgrade?: boolean;
  oldSubscription?: any;
};

export interface StripeResponse {
  id: string;
  object: string;
  automatic_payment_methods: string | null;
  cancellation_reason: string | null;
  client_secret: string;
  created: number;
  description: string | null;
  last_setup_error: string | null;
  livemode: boolean;
  next_action: string | null;
  payment_method: string;
  payment_method_configuration_details: string | null;
  payment_method_types: string[];
  status: string;
  usage: string;
}

export interface DevicesInSpace {
  device_id: string;
  id: string;
  space_id: string;
  name: string;
  location: string;
  manufacturer: Manufacturer;
  device_state: DeviceState;
  activation_date: string;
  pairing_status: string;
}

export interface Manufacturer {
  name: string;
  model: string;
  year: number;
}

export interface DeviceState {
  battery_percentage: number;
  wifi_name: string;
  status: string;
  firmware_version: string;
  ip_addr: string;
  last_seen: string;
}

function calculateAmount(
  isFreeTrial: boolean,
  isUpgrade: boolean,
  proRataPrice: any,
  current_price: number
) {
  if (isFreeTrial) {
    return 0;
  }
  if (isUpgrade) {
    return proRataPrice;
  }
  return current_price;
}

export default function PaymentOptions(props: IProps) {
  const location = useLocation();
  const {
    planSelected,
    purchaseId,
    toggleModal,
    isUpgrade,
    isFreeTrial,
    deviceId,
    dontAddDevice,
    oldSubscription,
    selectedDevice
  } = Object.keys(props).length > 0 ? props : location.state;

  console.log('PaymentOptions --> props = ', props);
  console.log('PaymentOptions --> location.state = ', location.state);
  console.log('PaymentOptions --> dontAddDevice = ', dontAddDevice);

  const [purchaseStatus, setPurchaseStatus] = useState<boolean | null>(null);
  const [paymentOption, setPaymentOption] = useState<PaymentMethod[]>([]);
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const isMobile = IsMobile();
  const [paymentOptionSelected, setPaymentOptionSelected] =
    useState<string>('');
  const navigate = useNavigate();
  const [selectedSpaceDeviceData, setSelectedSpaceDeviceData] = useState<
    DevicesInSpace[]
  >([]);
  const subscriptionData = useAppSelector(
    (state) => state.subscription.subscriptionData[0]
  );
  const [pollingCount, setPollingCount] = useState(0);
  const [proRataPrice, setProRataPrice] = useState(null);
  const handleChange = (e: { target: { value: string } }) => {
    setPaymentOptionSelected(e.target.value);
  };
  const config = useContext(ConfigContext);
  const fetchAllDevices = () => {
    axios
      .get(endpoints.listDevicesUrl)
      .then((res) => {
        setSelectedSpaceDeviceData(res.data.data.devices);
      })
      .catch((err) => {
        console.log('err', err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const fetchProRataDetails = () => {
    axios
      .get(`/piggybank/subscriptions/${purchaseId}/prorate_charges`)
      .then((res) => {
        setProRataPrice(res.data.upgrade_price);
      })
      .catch((err) => {
        console.log('fetchProRataDetails ERR', err);
      });
  };

  useEffect(() => {
    fetchAllDevices();
    if (isUpgrade) {
      fetchProRataDetails();
    }

    GetPaymentMethods()
      .then((res) => {
        setPaymentOption(res.data.payment_method || []);
        if (res.data?.payment_method?.length > 0) {
          setPaymentOptionSelected(res?.data?.payment_method[0]['id']);
        }
      })
      .catch((err) => console.log('err payment_methods', err));

    return () => {
      dispatch(fetchAllSubscription());
    };
  }, []);

  const getSupportedCameraCount = (supportedFeatures: any) => {
    const cameraSupportedCount = supportedFeatures
      .find((item: any) => item.id.includes('camera_supported'))
      ?.id.split('_')[0];

    return cameraSupportedCount === 'unlimited' ? 99 : 1;
  };

  const addCameraToSubscription = async (subscriptionObj: any) => {
    const supportedCameraCount = getSupportedCameraCount(
      subscriptionObj.supported_features
    );
    let payload = {};
    // debugger;

    if (isUpgrade) {
      const device_ids = oldSubscription.devices.map((el: any) => el?.id);

      payload = {
        device_ids
      };
      // debugger;
    } else {
      if (supportedCameraCount > 1) {
        const deviceArray = selectedSpaceDeviceData.map((el) => el?.device_id);
        const device_ids = deviceArray;
        payload = {
          device_ids
        };
      } else {
        payload = {
          device_ids: [selectedDevice]
        };
      }
    }

    let errorMsg = '';
    // debugger;
    axios
      .post(
        `/api/v1/parents/subscriptions/${subscriptionObj.purchase_id}/devices`,
        payload
      )
      .then((res) => {
        console.log('addCameraToSubscription success', res);
        dispatch(fetchAllSubscription());
        setPurchaseStatus(true);

        navigate('/payment-success', {
          state: {
            isFreeTrial,
            isUpgrade,
            errorMessage: errorMsg,
            showSetupComplete: Boolean(deviceId),
            deviceId,
            purchaseId: subscriptionObj.purchase_id,
            oldSubscription
          }
        });
      })
      .catch((err) => {
        console.log('addCameraToSubscription err->>', err);
        errorMsg =
          'Sorry! We cannot add the device to your selected to your subscription plan';
        setErrorMessage(
          'Sorry! We cannot add the device to your selected to your subscription plan'
        );
        console.log('addCameraToSubscription', err);
        setPurchaseStatus(false);
      })
      .finally(() => {
        setIsLoading(false);
        // debugger;
      });
    // if (deviceId) {
    //   authenticatDevice();
    // }
  };

  const findSubscriptionByPurchaseId = (subscriptions: any) => {
    // debugger;

    const found = subscriptions.filter(
      (item: any) => item.purchase_id === purchaseId
    );
    if (found.length) {
      return found[0];
    }
    return false;
  };

  const polling = () => {
    setTimeout(() => {
      GetSubscriptionDetails()
        .then((res) => {
          // debugger;
          const found = findSubscriptionByPurchaseId(res.data.subscriptions);
          // debugger;

          if (found && found.purchase_id === purchaseId) {
            // debugger;
            addCameraToSubscription(found);
          } else {
            // debugger;

            console.log('pollingCount', pollingCount, Date.now());
            setPollingCount((prevState) => {
              if (prevState < 5) {
                polling();
              } else {
                setPurchaseStatus(false);
                setIsLoading(false);
              }
              return prevState + 1;
            });
          }
        })
        .catch((err) => {
          // debugger;

          setIsLoading(false);
          setPurchaseStatus(false);
          console.log('GetSubscriptionDetails ERROR', err);
        });
    }, 1000);
  };

  const submitPurchaseRequest = (value?: string | null) => {
    setIsLoading(true);
    const payload = {
      purchase_id: purchaseId as string,
      stripe_payment_method: value || paymentOptionSelected
    };
    // debugger;

    const startPurchase = isUpgrade
      ? UpgradeUserSubscription
      : BuyUserSubscription;

    // debugger;

    startPurchase(payload)
      .then((res) => {
        if (res.data.status === 'succeeded' || res.data.status === '') {
          // debugger;

          polling();
        } else {
          // debugger;

          setPurchaseStatus(false);
        }
      })
      .catch((err) => {
        setPurchaseStatus(false);
        console.log('err submitPurchaseRequest', err);
      });
  };

  console.log('planSelected', planSelected);
  const { name, prices } = planSelected || {};
  const { currency, current_price } = (Array.isArray(prices) && prices[0]) || {
    currency: 'USD'
  };
  if (subscriptionData === undefined) {
    <Flex style={{ position: 'fixed', top: '50%', left: '50%' }}>
      <CircularProgress />
    </Flex>;
  }
  let refDate;
  let today;
  let diff;
  if (subscriptionData) {
    refDate = subscriptionData?.trial
      ? dayjs(subscriptionData.trial_start_date)
      : dayjs(subscriptionData.pay_date);
    today = dayjs(new Date());
    diff = today.diff(dayjs(refDate), 'day', true);
    diff = diff < 1 ? Math.ceil(diff) : Math.floor(diff);
  } else {
    today = dayjs(new Date());
    refDate = '';
    diff = '';
  }

  if (purchaseStatus == null) {
    return (
      <div>
        {isLoading && (
          <Flex
            style={{ position: 'fixed', top: '50%', left: '50%', zIndex: 1300 }}
          >
            <CircularProgress />
          </Flex>
        )}

        <Box>
          {isFreeTrial && (
            <>
              <Box>
                <Typography
                  variant="h1"
                  textAlign="center"
                  sx={{
                    textTransform: 'capitalize',
                    marginBottom: (theme) => theme.spacing(1.5),
                    fontSize: '22px',
                    fontWeight: '500'
                  }}
                >
                  You Are Almost Done
                </Typography>
                <Typography
                  mt={1.5}
                  mb={2}
                  sx={{
                    textAlign: 'center',
                    fontSize: '16px',
                    fontWeight: '400',
                    color: '#484C4F'
                  }}
                >
                  We will notify you by email a day before your free trial
                  converts to a subscription.
                </Typography>
              </Box>
              <Card
                style={{
                  borderRadius: '20px',
                  padding: '20px',
                  background: '#EDEDED',
                  boxShadow: 'none'
                }}
              >
                <Box textAlign="center" sx={{ margin: '16px' }}>
                  <Typography
                    variant="h3"
                    padding="0 10px 16px 10px"
                    textAlign="center"
                    fontWeight="500"
                  >
                    {name}
                  </Typography>
                  <Typography variant="h1" color="primary">
                    {CURRENCY[currency]}
                    {0}
                  </Typography>
                  <Typography variant="Body1Medium" color="#00000066">
                    First{' '}
                    {isUpgrade
                      ? FREE_TRIAL_DURATION - Number(diff)
                      : `${FREE_TRIAL_DURATION}`}{' '}
                    days
                    {/* First 7 days */}
                  </Typography>
                </Box>
                <Divider />
                <Typography
                  variant="h4"
                  paddingTop="12px"
                  fontWeight="400"
                  lineHeight="1.5"
                >
                  Cancel anytime before free trial ends
                  <br /> Turn off auto renewal anytime
                </Typography>
              </Card>
            </>
          )}
          {!isFreeTrial && (
            <InstaCard>
              <Typography variant="h2" padding="0 10px 16px 10px">
                {name}
                {planSelected.subscription_cycle === 1
                  ? ` - Monthly`
                  : ` - Yearly`}
              </Typography>

              <Flex wrap="wrap" justify="space-between" ptop="16">
                {planSelected.supported_features.map((element: IFeature) => {
                  const icon = obj[element.id];
                  return (
                    <Flex
                      align="center"
                      key={element.id}
                      mbottom="10"
                      style={{ width: isMobile ? '100%' : '50%' }}
                      wrap="wrap"
                    >
                      <Image width="20" height="20" src={icon} />
                      <PlanDetailPoint>{element.description}</PlanDetailPoint>
                    </Flex>
                  );
                })}
              </Flex>
              <Stack
                justifyContent="space-between"
                direction="row"
                padding="16px 10px 0 10px"
              >
                {!isUpgrade && (
                  <>
                    <Typography variant="h3">Total</Typography>

                    <Stack alignItems="flex-end">
                      <Typography variant="h3">
                        {CURRENCY[currency]}
                        {Number(current_price) / 100}
                      </Typography>
                    </Stack>
                  </>
                )}
                {isUpgrade && (
                  <>
                    <Typography variant="h3">
                      Prorated adjusted total
                    </Typography>

                    <Stack alignItems="flex-end">
                      <Typography variant="h3">
                        {CURRENCY[currency]}
                        {Number(proRataPrice) / 100}
                      </Typography>
                    </Stack>
                  </>
                )}
              </Stack>
            </InstaCard>
          )}
        </Box>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          name="radio-buttons-group"
          value={paymentOptionSelected}
          onChange={handleChange}
        >
          {paymentOption.map(
            (el: { id: string; card: { brand: string; last4: string } }) => {
              const {
                id,
                card: { last4, brand }
              } = el;
              const { src, width, height } = getCardImageProps(brand);
              return (
                <FormControlLabel
                  key={id}
                  value={id}
                  control={<Radio />}
                  label={
                    <>
                      <Image
                        src={src}
                        width={width}
                        height={height}
                        style={{ marginRight: '10px' }}
                      />{' '}
                      **** **** **** {last4}
                    </>
                  }
                />
              );
            }
          )}

          {paymentOption.length > 0 && (
            <FormControlLabel
              value="ADDCARD"
              control={<Radio />}
              label="ADD CARD"
            />
          )}
        </RadioGroup>
        {paymentOptionSelected === 'ADDCARD' || paymentOption.length === 0 ? (
          <>
            <Typography variant="h3" sx={{ mt: '30px', mb: '16px' }}>
              Card Details
            </Typography>
            <PaymentScreenDemo
              isUpgrade={isUpgrade}
              isFreeTrial={isFreeTrial}
              amount={calculateAmount(
                isFreeTrial,
                isUpgrade,
                proRataPrice,
                current_price
              )}
              submitPurchaseRequest={submitPurchaseRequest}
            />
          </>
        ) : (
          <Flex
            mtop="10"
            mbottom="20"
            justify="center"
            style={{
              width: '100%'
            }}
          >
            <Button
              disabled={isLoading}
              variant="contained"
              sx={{
                outline: 'none',
                textTransform: 'none',
                '&:focus': {
                  outline: 'none'
                }
              }}
              style={{
                color: '#fff',
                height: '52px',
                width: '100%',
                maxWidth: '300px',
                borderRadius: '44px',
                fontSize: '16px',
                fontWeight: '600',
                lineHeight: '24px'
              }}
              onClick={() => submitPurchaseRequest(null)}
            >
              {isFreeTrial && !isUpgrade && 'Start Free Trial'}
              {isFreeTrial && isUpgrade && 'Upgrade Free Trial'}
              {!isFreeTrial &&
                !isUpgrade &&
                `Pay $${current_price && current_price / 100}`}
              {!isFreeTrial &&
                isUpgrade &&
                `Pay ${CURRENCY[currency]}${
                  proRataPrice && (proRataPrice / 100).toFixed(2)
                }`}
            </Button>
          </Flex>
        )}
      </div>
    );
  }

  if (purchaseStatus === true) {
    return (
      <>
        <Flex align="flex-start" direction="column">
          <Flex align="flex-start" mbottom="24">
            <Flex>
              <Image src={PaymentSuccess} width="60" height="60" />
            </Flex>

            <Flex direction="column" align="start" mleft="10">
              <Flex direction="column" align="start">
                <Flex>
                  <Info>The payment was successful. </Info>
                </Flex>

                {isFreeTrial && !isUpgrade && (
                  <Info>Your free trial has been activated!</Info>
                )}
                {isFreeTrial && isUpgrade && (
                  <Info>Your free trial upgrade has been activated!</Info>
                )}
                {!isFreeTrial && !isUpgrade && (
                  <Info>Your subscription has been activated!</Info>
                )}
                {!isFreeTrial && isUpgrade && (
                  <Info>Your Upgraded subscription has been activated!</Info>
                )}

                {errorMessage !== '' && (
                  <Flex direction="column" align="baseline">
                    {/* <Image src={AddCameraFail} width="62" height="62" /> */}
                    <Heading>
                      Sorry! We were unable add the device your subscription
                      plan
                    </Heading>
                    <Content>Please contact customer service via:</Content>
                    <Flex align="center">
                      <Content>Email: </Content>
                      <ContactInfo>{config.email}</ContactInfo>
                    </Flex>

                    <Flex align="center">
                      <Content>Phone number: </Content>
                      <ContactInfo>{config.phone}</ContactInfo>
                    </Flex>
                  </Flex>
                )}
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <Flex justify="center">
          <Button variant="outlined" onClick={toggleModal}>
            Go To Subscription
          </Button>
        </Flex>
      </>
    );
  }

  if (purchaseStatus === false) {
    return (
      <>
        <Flex align="flex-start" direction="column">
          <Flex align="center" mbottom="24">
            <Image src={PaymentFailure} width="60" height="60" />
            <Flex direction="column" align="start" mleft="10">
              <Info>Payment Declined</Info>
              <Info>Oh snap! The credit card information was declined</Info>
            </Flex>
          </Flex>
        </Flex>
        <Flex justify="center">
          <Button variant="outlined" onClick={() => setPurchaseStatus(null)}>
            Try Again
          </Button>
        </Flex>
      </>
    );
  }

  return <Spinner />;
}
